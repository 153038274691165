// A family of inputs that can be used with or without the optional label prop
//  - onChange functions will always return the new value (NOT the event)
//  - inputs that accept options will take options in any of the following formats
//    - array of single elements: ['Orange', 'Green']
//    - array of label/value: [['Label 1', 'key1'], ['Label 2', 'key2']]
//    - array of objects: [{ label: 'Label', value: 'val' }]

/* eslint-disable @nitid/nitid/jsx-export-match-filename */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import CurrencyInput from 'react-currency-input-field'
import _uniqueId from 'lodash/uniqueId'
import VisibilityIcon from './VisibilityIcon.jsx'
import phoneNumberFormatter from '../../phone_number_formatter'
import { urlAddHttps } from '../../../utilities/utilities.ts'
import './OrgInputs.scss'

export const PHONE_PATTERN = '^1?\\s?\\(\\d{3}\\)\\s\\d{3}-\\d{4}(x\\d+)?$'
const URL_PATTERN =
  '^(https?:\\/\\/)?(www\\.)?[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]{2,}(\\/[^\\s]*)?$'
const EMAIL_PATTERN = '^\\S+@\\S+$'

export const RADIO_ANY_VALUE = 'any'

// [navigator_support_org][name] => navigator_support_org__name (or a unique id if no name is provided)
const nameToId = (name) =>
  name ? name.replace(/\W/g, '_').replace(/(^_|_+$)/g, '') : _uniqueId('inp-')

const isInReactSelectFormat = (v) => v instanceof Object && !Array.isArray(v)
const formatOption = (option) => {
  if (!option) return null
  if (isInReactSelectFormat(option)) return option
  if (Array.isArray(option) && option.length === 2)
    return { value: option[1], label: option[0] }
  return { value: option, label: option }
}
const unformatOptions = (options) =>
  Array.isArray(options)
    ? options.map((o) => o.value)
    : options && options.value

// The react-select wants us to pass in the ENTIRE value/label object to be selected, not just the value.
// However, I don't want to store its special object format. We can just retrieve it with the value here.
const getValue = (allOptions, value) => {
  if (!value) return ''

  const findSingleValue = (v) =>
    allOptions.find((op) => op.value === formatOption(v)?.value)

  if (
    isInReactSelectFormat(value) ||
    (Array.isArray(value) && value.every((el) => isInReactSelectFormat(el)))
  )
    return value
  // no need to convert; it's already in the expected format

  // eslint-disable-next-line no-console
  if (!Array.isArray(allOptions)) return console.warn('allOptions missing')

  if (Array.isArray(value)) return value.map((elem) => findSingleValue(elem))
  // this must be an isMulti select; we need to return an array of values

  return findSingleValue(value) || ''
}

const getOrgInputClassName = ({
  specificClass,
  singleRow = false,
  hidden = false,
}) =>
  `OrgInput ${specificClass} ${singleRow ? 'single-row' : ''} ${
    hidden ? 'hidden' : ''
  }`

// this is NOT exported because it's not an OrgInput; use OrgInputPlainLabel instead
const OrgInputLabel = ({
  attribute,
  extraStyle = '',
  inputId,
  isInfoPublic = null,
  label,
  privateSupportOrg,
  spanStyle,
}) => {
  if (!label) return ''

  return (
    <label
      className={`org-input-label ${extraStyle}`}
      htmlFor={inputId || undefined} // undefined prevents empty 'for' attribute on html element
    >
      <span className={spanStyle}>{label}</span>
      {typeof isInfoPublic === 'boolean' && (
        <VisibilityIcon
          isInfoPublic={isInfoPublic}
          attribute={attribute}
          privateSupportOrg={privateSupportOrg}
        />
      )}
    </label>
  )
}

/* - START of exported components */

// for if a stanza wants just a label without any inputs associated
export const OrgInputPlainLabel = ({
  extraStyle = '',
  isInfoPublic,
  inputId = '',
  label,
  padding = false,
  spanStyle = '',
}) => (
  <div className={`OrgInput plain-label${padding ? '' : ' no-padding'}`}>
    <OrgInputLabel
      label={label}
      inputId={inputId}
      isInfoPublic={isInfoPublic}
      extraStyle={extraStyle}
      spanStyle={spanStyle}
    />
  </div>
)

const defaultCalculateErrors = (val, required, pattern, errorLabel) => {
  if (!val && required) return `${errorLabel} is required`
  if (val && !val.match(pattern)) return `${errorLabel} is not valid`
  return false
}

export const OrgStringInput = ({
  disabled,
  errors = '',
  extraStyle = '',
  isInfoPublic,
  label,
  name,
  onBlur,
  onChange,
  onKeyDown,
  pattern = undefined,
  placeholder,
  required = false,
  singleRow = false,
  specificClass = 'text',
  type = 'text',
  value,
}) => {
  const inputDomId = nameToId(name)

  return (
    <div className={getOrgInputClassName({ specificClass, singleRow })}>
      <OrgInputLabel
        label={label}
        inputId={inputDomId}
        isInfoPublic={isInfoPublic}
        extraStyle={extraStyle}
      />
      <input
        type={type}
        className={`org-input string ${errors ? 'errors' : ''}`}
        disabled={disabled}
        name={name}
        id={inputDomId}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        required={required}
        pattern={pattern}
      />
      {errors && <div className="warning errors">{errors}</div>}
    </div>
  )
}

const OrgStringInputWithErrorHandling = ({
  calculateErrors = defaultCalculateErrors,
  errorLabel,
  isInfoPublic,
  label,
  name,
  onBlur,
  onChange,
  onKeyDown,
  pattern,
  placeholder,
  required = false,
  serverErrors,
  setErrors, // function to set error state on the parent; will return a bool
  showErrors = 'onBlur',
  singleRow,
  specificClass,
  type = 'text',
  value,
}) => {
  const [hasBlurred, setHasBlurred] = useState(false)

  const showErrorsNow = showErrors === 'onBlur' ? hasBlurred : showErrors
  const errors =
    serverErrors || calculateErrors(value, required, pattern, errorLabel)
  const handleChange = (newValue) => {
    if (typeof onChange === 'function') onChange(newValue)
    if (typeof setErrors === 'function')
      setErrors(!!calculateErrors(newValue, required, pattern, errorLabel))
  }

  const handleBlur = (e) => {
    if (typeof onBlur === 'function') onBlur(e)
    setHasBlurred(true)
  }

  useEffect(() => {
    if (typeof setErrors === 'function') setErrors(!!errors)
  }, [])

  return (
    <OrgStringInput
      errors={showErrorsNow && errors}
      isInfoPublic={isInfoPublic}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      pattern={showErrorsNow ? pattern : undefined}
      placeholder={placeholder}
      required={required}
      singleRow={singleRow}
      specificClass={specificClass}
      type={type}
      value={value}
      extraStyle={showErrorsNow ? 'warning' : ''}
    />
  )
}

export const OrgPhoneInput = ({
  errorLabel = 'Phone Number',
  errors,
  isInfoPublic,
  label,
  name = '',
  onBlur,
  onChange,
  placeholder,
  required,
  setErrors,
  singleRow,
  showErrors = 'onBlur',
  value,
}) => (
  <OrgStringInputWithErrorHandling
    label={label}
    name={name}
    // type="tel" commented out because this makes the format weird
    onChange={(newValue) =>
      onChange(phoneNumberFormatter(newValue, phoneNumberFormatter(value)))
    }
    errors={errors}
    errorLabel={errorLabel}
    isInfoPublic={isInfoPublic}
    onBlur={onBlur}
    pattern={PHONE_PATTERN}
    placeholder={placeholder}
    required={required}
    setErrors={setErrors}
    showErrors={showErrors}
    singleRow={singleRow}
    value={phoneNumberFormatter(value)}
  />
)

export const OrgUrlInput = ({
  errorLabel = 'URL',
  isInfoPublic,
  label,
  name = '',
  onBlur,
  onChange,
  placeholder,
  required,
  serverErrors,
  setErrors,
  showErrors = 'onBlur',
  singleRow,
  value,
}) => (
  <OrgStringInputWithErrorHandling
    errorLabel={errorLabel}
    serverErrors={serverErrors}
    isInfoPublic={isInfoPublic}
    label={label}
    name={name}
    onBlur={(e) => {
      urlAddHttps(e.target.value, onChange)
      if (typeof onBlur === 'function') onBlur(e)
    }}
    onChange={onChange}
    pattern={URL_PATTERN}
    placeholder={placeholder}
    required={required}
    setErrors={setErrors}
    showErrors={showErrors}
    singleRow={singleRow}
    type="url"
    value={value}
  />
)

export const OrgEmailInput = ({
  errorLabel = 'Email',
  isInfoPublic,
  label,
  name = '',
  onBlur,
  onChange,
  placeholder,
  required,
  serverErrors,
  setErrors,
  showErrors = 'onBlur',
  singleRow,
  specificClass,
  value,
}) => (
  <OrgStringInputWithErrorHandling
    errorLabel={errorLabel}
    serverErrors={serverErrors}
    isInfoPublic={isInfoPublic}
    label={label}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    pattern={EMAIL_PATTERN}
    placeholder={placeholder}
    required={required}
    setErrors={setErrors}
    showErrors={showErrors}
    singleRow={singleRow}
    specificClass={specificClass}
    type="email"
    value={value}
  />
)

export const OrgDateInput = ({
  disabled,
  errors,
  extraStyle = '',
  isInfoPublic,
  label,
  name,
  onBlur,
  onChange,
  showErrors,
  value,
}) => {
  return (
    <OrgStringInput
      disabled={disabled}
      errors={errors}
      extraStyle={extraStyle}
      isInfoPublic={isInfoPublic}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      showErrors={showErrors}
      type="date"
      value={value || ''}
    />
  )
}

export const OrgDollarsInput = ({
  name,
  label,
  value,
  onChange,
  visible,
  extraStyle = '',
}) => {
  const inputDomId = nameToId(name)

  return (
    <div className="OrgInput text">
      <OrgInputLabel
        label={label}
        inputId={inputDomId}
        visible={visible}
        extraStyle={extraStyle}
      />
      <CurrencyInput
        id={inputDomId}
        className="org-input string currency"
        data-type="currency"
        name={name}
        placeholder="$0.00"
        onValueChange={(stringIncluding$sign) => onChange(stringIncluding$sign)}
        value={value || ''}
        prefix="$"
        decimalsLimit={2}
        decimalScale={0}
        allowNegativeValue={false}
      />
    </div>
  )
}

export const OrgTextInput = ({
  disabled = false,
  extraStyle = '',
  hidden = false,
  isInfoPublic,
  label,
  name,
  onChange,
  placeholder,
  singleRow = false,
  value,
}) => {
  const inputDomId = nameToId(name)

  return (
    <div className={getOrgInputClassName({ specificClass: 'text', singleRow })}>
      {!hidden && (
        <OrgInputLabel
          label={label}
          inputId={inputDomId}
          isInfoPublic={isInfoPublic}
          extraStyle={extraStyle}
        />
      )}
      <textarea
        className="org-input text"
        disabled={disabled}
        hidden={hidden}
        id={inputDomId}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        rows="5"
        value={value || ''}
      />
    </div>
  )
}

// the react-select component does not allow us to style the css without passing it into their component like this
const inputStyle = {
  control: (baseStyles, _state) => ({
    ...baseStyles,
    fontSize: '14px', // controls the selected item font size but not the dropdown items
    fontFamily: 'MetaPro',
    lineHeight: '18px',
    color: '#454545',
    backgroundColor: '#fdfdfd',
    // $error_state_red border
    borderColor:
      _state.selectProps.className.indexOf('unvalid') > -1
        ? '#c54261'
        : baseStyles.borderColor,
    '&:hover': {
      borderColor:
        _state.selectProps.className.indexOf('unvalid') > -1
          ? '#c54261'
          : baseStyles.borderColor,
    },
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: '16px', // font size end up being ~14 because something in react-select 85%s it
    fontFamily: 'MetaPro',
    backgroundColor: '#e5e5e5',
    color: '#406dc0',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: '#406dc0',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: '#fdfdfd',
  }),
}

const computeExpandsOnOpenStyles = (margin) => ({
  ...inputStyle,
  control: (baseStyles, _state) => ({
    ...baseStyles,
    fontSize: '14px',
    fontFamily: 'MetaPro',
    lineHeight: '18px',
    color: '#454545',
    backgroundColor: '#fdfdfd',
    borderColor:
      _state.selectProps.className.indexOf('unvalid') > -1
        ? '#c54261'
        : baseStyles.borderColor,
    '&:hover': {
      borderColor:
        _state.selectProps.className.indexOf('unvalid') > -1
          ? '#c54261'
          : baseStyles.borderColor,
    },
    marginBottom: _state.isFocused ? margin : 0,
  }),
})

export const OrgSelectInput = ({
  bulkEdit = false,
  disabled,
  errors = '',
  expandsOnOpen,
  expandsOnOpenMargin,
  extraStyle = '',
  hidden = false,
  isClearable,
  isInfoPublic = null,
  isMulti,
  isValueDisabled,
  label,
  labelVisuallyHidden = false,
  name,
  onChange,
  onKeyDown,
  options,
  placeholder,
  singleRow = false,
  unvalid = false,
  value,
}) => {
  const [_isMenuOpen, _setIsMenuOpen] = useState() // observing react-select internal state

  const inputDomId = nameToId(name)
  const formattedOptions = (options || []).map((opt) => formatOption(opt))
  const maybeIsOptionDisabled = isValueDisabled
    ? { isOptionDisabled: (option) => isValueDisabled(option.value) }
    : {}

  const blankOption = formattedOptions.find((option) => option.value === '')
  if (blankOption && placeholder && !bulkEdit)
    console.warn(
      "Provide a placeholder or an option with value='' but not both"
    )

  const styles =
    expandsOnOpen && _isMenuOpen
      ? computeExpandsOnOpenStyles(expandsOnOpenMargin || '210px')
      : inputStyle

  return (
    <div
      className={getOrgInputClassName({
        specificClass: 'select',
        singleRow,
        hidden,
      })}
    >
      <OrgInputLabel
        label={label || ''}
        inputId={inputDomId}
        isInfoPublic={isInfoPublic}
        extraStyle={`${extraStyle} ${
          labelVisuallyHidden ? 'visually-hidden' : ''
        }`}
      />
      <Select
        className={`org-input select${unvalid ? ' unvalid' : ''}`}
        inputId={inputDomId}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        name={name}
        onChange={(newValue) => onChange(unformatOptions(newValue))}
        onKeyDown={onKeyDown}
        onMenuClose={() => _setIsMenuOpen(false)}
        onMenuOpen={() => _setIsMenuOpen(true)}
        options={formattedOptions}
        placeholder={placeholder || blankOption?.label}
        styles={styles}
        value={getValue(formattedOptions, value)}
        {...maybeIsOptionDisabled}
      />
      {errors && <span className="warning errors">{errors}</span>}
    </div>
  )
}

const formattableOptionPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])), // expecting a two element array
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
])

const formatOptions = (options) =>
  options
    .map((op) => formatOption(op))
    .filter((op) => op) // remove nulls
    .sort((a, b) => (a.label > b.label ? 1 : -1))

export const OrgCreatableSelectInput = ({
  errors = '',
  hidden = false,
  isClearable,
  isInfoPublic,
  isMulti,
  label,
  name,
  onChange,
  options,
  singleRow = false,
  unvalid = false,
  value,
}) => {
  const inputDomId = nameToId(name)

  let formattedOptions
  let valueForCreatable

  if (value) {
    // need to add any user-added values to the list of options
    const optionsWithAdded = options.concat(
      value.filter((val) => !options.includes(val)).flat()
    )
    formattedOptions = formatOptions(optionsWithAdded)
    valueForCreatable = getValue(formattedOptions, value)
  } else {
    formattedOptions = formatOptions(options)
    valueForCreatable = undefined
  }

  return (
    <div
      className={getOrgInputClassName({
        specificClass: 'select',
        singleRow,
        hidden,
      })}
    >
      <OrgInputLabel
        label={label}
        inputId={inputDomId}
        isInfoPublic={isInfoPublic}
      />
      <div data-testid="react-creatable-component">
        <CreatableSelect
          className={`org-input select creatable${unvalid ? ' unvalid' : ''}`}
          name={name}
          inputId={inputDomId}
          value={valueForCreatable}
          onChange={(newValue) => onChange(unformatOptions(newValue))}
          isMulti={isMulti}
          isClearable={isClearable}
          options={formattedOptions}
          styles={inputStyle}
        />
      </div>
      {errors && <span className="warning errors">{errors}</span>}
    </div>
  )
}

export const OrgCheckboxInput = ({
  attribute,
  checked,
  isInfoPublic,
  label,
  name = '',
  onChange,
  privateSupportOrg,
  required = null,
}) => {
  const inputDomId = nameToId(name)
  return (
    <div className="OrgInput single-line">
      <input type="hidden" name={name} value="0" />
      <input
        type="checkbox"
        name={name}
        value="1"
        onChange={(e) => onChange(e.target.checked)}
        id={inputDomId}
        checked={!!checked}
        required={required}
      />
      <OrgInputLabel
        label={label}
        inputId={inputDomId}
        isInfoPublic={isInfoPublic}
        attribute={attribute}
        privateSupportOrg={privateSupportOrg}
        extraStyle="not-serif"
      />
    </div>
  )
}

export const OrgArrayCheckboxInput = ({ value, options, onChange }) => (
  // TODO: Make this accept name, etc. For now it is not a form input,
  // just an interactive input

  <div className="OrgInput OrgArrayCheckboxInput">
    {options
      .map((opt) => formatOption(opt))
      .map((opt) => (
        <OrgCheckboxInput
          label={opt.label}
          key={opt.value}
          checked={value?.includes(opt.value) || false}
          onChange={(bool) =>
            onChange(
              bool
                ? [...(value || []), opt.value]
                : (value || []).filter((x) => x !== opt.value)
            )
          }
        />
      ))}
  </div>
)

export const OrgInputGroup = ({ children, errors, label }) => (
  <div className="OrgInputGroup">
    <OrgInputPlainLabel label={label} padding={true} />
    {children}
    {errors && <span className="warning errors">{errors}</span>}
  </div>
)

export const OrgRadioInput = ({
  checked,
  label,
  name,
  onChange,
  onSelected,
  onUnselected,
  required = false,
  value,
}) => {
  const inputDomId = _uniqueId('radio-inp-')
  const handleRadioChange = (event) => {
    if (onChange) onChange(event)
    if (event.target.checked && onSelected) onSelected()
    if (!event.target.checked && onUnselected) onUnselected()
  }
  return (
    <div className="OrgInput single-line">
      <input
        type="radio"
        name={name}
        value={value}
        onChange={handleRadioChange}
        id={inputDomId}
        checked={!!checked}
        required={required}
      />
      <OrgInputLabel
        label={label}
        inputId={inputDomId}
        extraStyle="not-serif"
      />
    </div>
  )
}

export const OrgArrayRadioInput = ({ value, options, onChange, name }) => (
  <div className="OrgInput OrgArrayRadioInput">
    {options.map((option) => {
      // default to the "any" value if nothing is selected...
      const isCheckedBecauseValueIsBlank =
        !value && option.value === RADIO_ANY_VALUE
      // and also if "any" is selected, apply no value to this filter
      const onClickValue = option.value === RADIO_ANY_VALUE ? '' : option.value
      return (
        <label key={option.value} className="radio-subgroup">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={isCheckedBecauseValueIsBlank || value === option.value}
            onChange={() => onChange(onClickValue)}
          />
          <span className="radio-label">{option.label}</span>
        </label>
      )
    })}
  </div>
)

// assume any props not provided will default to undefined and be ok
/* eslint-disable react/require-default-props */

const numberOrStringPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

const baseInputPropTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // false is ok, indicating no error. Should never be true.
  extraStyle: PropTypes.string,
  isInfoPublic: PropTypes.bool,
  singleRow: PropTypes.bool,
}

OrgInputLabel.propTypes = {
  attribute: PropTypes.string,
  extraStyle: PropTypes.string,
  inputId: PropTypes.string,
  isInfoPublic: PropTypes.bool,
  label: PropTypes.string.isRequired,
  privateSupportOrg: PropTypes.bool,
  spanStyle: PropTypes.string,
}

OrgInputPlainLabel.propTypes = {
  extraStyle: PropTypes.string,
  isInfoPublic: PropTypes.bool,
  inputId: PropTypes.string,
  label: PropTypes.string.isRequired,
  padding: PropTypes.bool,
  spanStyle: PropTypes.string,
}

OrgCreatableSelectInput.propTypes = {
  ...baseInputPropTypes,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(numberOrStringPropType),
  ]),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(formattableOptionPropType).isRequired,
  unvalid: PropTypes.bool,
  hidden: PropTypes.bool,
}

OrgSelectInput.propTypes = {
  ...OrgCreatableSelectInput.propTypes,
  expandsOnOpen: PropTypes.bool,
  expandsOnOpenMargin: PropTypes.string,
}

OrgTextInput.propTypes = {
  ...baseInputPropTypes,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
}

OrgCheckboxInput.propTypes = {
  ...baseInputPropTypes,
  checked: PropTypes.bool.isRequired,
  attribute: PropTypes.string,
  privateSupportOrg: PropTypes.bool,
}

// PropTypes for OrgDateInput
OrgDateInput.propTypes = {
  ...baseInputPropTypes,
  disabled: PropTypes.bool,
  showErrors: PropTypes.string,
}

// PropTypes for OrgDollarsInput
OrgDollarsInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
  extraStyle: PropTypes.string,
}

OrgArrayCheckboxInput.propTypes = {
  value: PropTypes.arrayOf(numberOrStringPropType),
  options: PropTypes.arrayOf(formattableOptionPropType).isRequired,
  onChange: PropTypes.func.isRequired,
}

OrgInputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // false is ok, indicating no error. Should never be true.
  label: PropTypes.string.isRequired,
}

OrgRadioInput.propTypes = {
  ...baseInputPropTypes,
  checked: PropTypes.bool.isRequired,
  onSelected: PropTypes.func,
  onUnselected: PropTypes.func,
}
